<!--
 * @Descripttion: 
 * @version: 
 * @Author: liwh
 * @Date: 2022-04-15 11:29:01
 * @LastEditors: liwh
 * @LastEditTime: 2023-03-24 10:11:19
-->
<template>
  <div id="app" ref="app">
    <router-view />
    <!-- <el-dialog v-model="dialogTableVisible" center   :close-on-click-modal="false"> -->
      <!-- <h1 style="font-size:50px;text-align:center;" >请横屏操作</h1> -->
    <!-- </el-dialog> -->
  </div>
</template>

<script>
export default {
  name: "App",
//   data() {
//     // return {
//     //   clientWidth: document.body.clientWidth,
//     //   clientHeight: document.body.clientHeight,
//     //   dialogTableVisible: false,
//     // };
//   },
//   mounted() {
//     let that = this;
//     window.onresize = () => {
//       return (() => {
//         window.clientWidth = document.body.clientWidth;
//         that.clientWidth = window.clientWidth;
//         window.clientHeight = document.body.clientHeight;
//         that.clientHeight = window.clientHeight;
//       })();
//     };
//   },
//   watch: {
//     clientWidth: {
//       immediate: true,
//       handler(newVal, oldVal) {
//         console.log(newVal, oldVal, this.clientHeight);
//         if (newVal < this.clientHeight) {
//           this.dialogTableVisible = true;
//         } else {
//           this.dialogTableVisible = false;
//         }
//         // do something
//       },
//     },
//   },
};
</script>

<style>
@font-face {
  font-family: "douyu";
  src: url("./assets/font/16 DOUYU Font.otf");
}
@font-face {
  font-family: "myfont";
  src: url("./assets/font/AlibabaPuHuiTi-2-55-Regular.ttf");
}
html,
body,
#app,
.theme {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", "PingFang SC", "Microsoft YaHei", "Helvetica Neue",
    "Hiragino Sans GB", "WenQuanYi Micro Hei", Arial, sans-serif !important;
  /* background-color: black; */
}
@media screen and (orientation: portrait) {
  /*竖屏样式*/
  body {
	display: none;
    /* transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%); */
  }
}
input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {  
    -webkit-text-fill-color: #ededed !important;  
    -webkit-box-shadow: none!important;  
    background-color:none;  
    background-image: none;  
    transition: background-color 50000s ease-in-out 0s;  
}  
input {  
    background-color:none;  
} 
/* :deep(.el-input__wrapper) {
  background-color: none !important;
} */
</style>

