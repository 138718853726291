/*
 * @Descripttion: 
 * @version: 
 * @Author: liwh
 * @Date: 2022-04-18 17:54:32
 * @LastEditors: liwh
 * @LastEditTime: 2022-06-16 09:45:13
 */
import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token,{ expires: 1 })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getAvatar() {
  return Cookies.get('avatar')
}

export function setAvatar(value) {
  return Cookies.set('avatar', value)
}

export function removeAvatar() {
  return Cookies.remove('avatar')
}

export function getLogo() {
  return Cookies.get('logo')
}

export function setLogo(value) {
  return Cookies.set('logo', value)
}

export function removeLogo() {
  return Cookies.remove('logo')
}

export function getCorporateName() {
  return Cookies.get('corporateName')
}

export function setCorporateName(value) {
  return Cookies.set('corporateName', value)
}

export function removeCorporateName() {
  return Cookies.remove('corporateName')
}