/*
 * @Descripttion: 
 * @version: 
 * @Author: liwh
 * @Date: 2022-04-15 11:29:01
 * @LastEditors: liwh
 * @LastEditTime: 2022-08-19 11:05:03
 */
// import {createApp} from 'vue'
// import App from '../App'
import { Message } from 'element-plus'
import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import { getToken } from '../utils/auth'
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' // 进度条样式
// const app = createApp(App)
// app.use(VueRouter)

NProgress.configure({ showSpinner: true }) // 进度条配置
const routes = [
  {
    path: '/',
    redirect: "/index",
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/login'),
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    meta: {
      title: '密码找回'
    },
    component: () => import('@/views/login/forgetPassword'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: '注册'
    },
    component: () => import('@/views/register/register'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index/index'),
    meta: {
      title: '无人船云平台',
      requireAuth: true,
    }
  },
  {
    path: '/perfectInfo',
    name: 'PerfectInfo',
    meta: {
      title: '完善资料',
      requireAuth: true,
    },
    component: () => import('@/views/perfectInfo/perfectInfo'),
  },
  // {
  //   path: '/lakelevel',
  //   name: 'Lakelevel',
  //   component: () => import('@/views/historicalData/lakeLevel'),
  //   meta: {
  //     requireAuth: true,
  //     title: "湖泊列表"
  //   }
  // },
  {
    path: '/tasklevel',
    name: 'Tasklevel',
    component: () => import('@/views/historicalData/taskLevel'),
    meta: {
      requireAuth: true,
      title: "行动列表"
    }
  },
  {
    path: '/samplingdatalevel',
    name: 'samplingDataLevel',
    component: () => import('@/views/historicalData/sampling/samplingdatalevel'),
    meta: {
      requireAuth: true,
      title: "采样数据"
    }
  },
  {
    path: '/waterdatalevel',
    name: 'waterDataLevel',
    component: () => import('@/views/historicalData/water/waterdatalevel'),
    meta: {
      requireAuth: true,
      title: "水质数据"
    }
  },

  {
    path: '/waterdatavisual',
    name: 'waterdatavisual',
    component: () => import('@/views/historicalData/water/waterdatavisual'),
    meta: {
      requireAuth: true,
      title: "水质数据3D可视化"
    }
  },
  {
    path: '/hotData',
    name: 'hotData',
    component: () => import('@/views/historicalData/water/hotData'),
    meta: {
      requireAuth: true,
      title: "水质数据热力图"
    }
  },

  /* 
  */
  {
    path: '/viewChartPage',
    name: 'viewChartPage',
    component: () => import('@/views/historicalData/water/viewChartPage'),
    meta: {
      requireAuth: true,
      title: "水质数据折线图"
    }
  },
  {
    path: '/water2dView',
    name: 'water2dView',
    component: () => import('@/views/historicalData/water/water2dView'),
    meta: {
      requireAuth: true,
      title: "水质数据2D可视化"
    }
  },
  {
    path: '/adcpdatalevel',
    name: 'adcpDataLevel',
    component: () => import('@/views/historicalData/ADCP/ADCPdatalevel'),
    meta: {
      requireAuth: true,
      title: "ADCP数据"
    }
  },
  {
    path: '/adcpdatavisual',
    name: 'adcpDataVisual',
    component: () => import('@/views/historicalData/ADCP/ADCPdatavisual'),
    meta: {
      requireAuth: true,
      title: "ADCP2D可视化"
    }
  },
  {
    path: '/mapOfADCPdatavisual',
    name: 'mapOfADCPdatavisual',
    component: () => import('@/views/historicalData/ADCP/MapOfADCPdatavisual'),
    meta: {
      requireAuth: true,
      title: "ADCP3D可视化"
    }
  },
  {
    path: '/algaeData',
    name: 'algaeData',
    component: () => import('@/views/historicalData/algae/algaeData'),
    meta: {
      requireAuth: true,
      title: "藻类数据"
    }
  },
  {
    path: '/operation',
    name: 'Operation',
    meta: {
      title: '操作中心',
      requireAuth: true,
    },
    component: () => import('@/views/operation/operation'),
  },
  {
    path: '/editWork',
    name: 'EditWork',
    component: () => import('@/views/operation/components/water/editWork'),
    meta: {
      title: '水质检测任务编辑',
      requireAuth: true,
    }
  },
  {
    path: '/slEditWork',
    name: 'SamplingEditWork',
    component: () => import('@/views/operation/components/sampling/editWork'),
    meta: {
      title: '采样任务编辑',
      requireAuth: true,
    }
  },
  {
    path: '/adcpEditWork',
    name: 'adcpEditWork',
    component: () => import('@/views/operation/components/ADCP/editWork'),
    meta: {
      title: 'ADCP任务编辑',
      requireAuth: true,
    }
  },
  {
    path: '/waSaEditWork',
    name: 'waSaEditWork',
    component: () => import('@/views/operation/components/waterAndSampling/editWork'),
    meta: {
      title: '采样检测任务编辑',
      requireAuth: true,
    }
  },
  {
    path: '/alarmpicture',
    name: 'alarmPicture',
    component: () => import('@/views/historicalData/alarmpicture'),
    meta: {
      title: '报警图片',
      requireAuth: true,
    }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('@/views/personal/personal'),
    meta: {
      title: '个人中心',
      requireAuth: true,
    }
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import('@/views/personal/edit'),
    meta: {
      title: '修改资料',
      requireAuth: true,
    }
  },
  {
    path: '/shipOrDock',
    name: 'shipOrDock',
    component: () => import('@/views/components/shipOrDock'),
    meta: {
      title: '系统列表',
      requireAuth: true,
    }
  },
  {
    path: '/Dock',
    name: 'Dock',
    component: () => import('@/views/dock/index'),
    meta: {
      title: '船坞系统',
      requireAuth: true,
    }
  },
  {
    path: '/DockList',
    name: 'DockList',
    component: () => import('@/views/dock/dockList'),
    meta: {
      title: '船坞列表',
      requireAuth: true,
    }
  },
  {
    path: '/adminShipList',
    name: 'adminShipList',
    component: () => import('@/views/components/adminShipList.vue'),
    meta: {
      title: '系统管理员',
      requireAuth: true,
    }
  },
  {
    path: '/dockDataView',
    name: 'dockDataView',
    component: () => import('@/views/dock/components/dataView.vue'),
    meta: {
      title: '数据总览',
      requireAuth: true,
    },
    redirect: "/weatherData",
    children: [
      {
        path: '/weatherData',
        name: "weatherData",
        component: () => import('@/views/dock/dataView/weatherData.vue'),
      },
      {
        path: '/shipData',
        name: "shipData",
        component: () => import('@/views/dock/dataView/shipData.vue'),
      }
    ]
  },
  {
    path: '/historyPath',
    name: 'historyPath',
    component: () => import('@/views/components/historyPath.vue'),
    meta: {
      title: '历史轨迹',
      requireAuth: true,
    }
  },
  {
    path: '/zhonghangList',
    name: 'zhonghangList',
    component: () => import('@/views/historicalData/zhonghang/zhonghangList.vue'),
    meta: {
      title: '列表',
      requireAuth: true,
    }
  },
  {
    path: '/data3Dview',
    name: 'data3Dview',
    component: () => import('@/views/historicalData/zhonghang/data3Dview.vue'),
    meta: {
      title: '数据可视化',
      requireAuth: true,
    }
  },
  {
    path: '/velocity3Dview',
    name: 'velocity3Dview',
    component: () => import('@/views/historicalData/zhonghang/velocity3Dview.vue'),
    meta: {
      title: '水流数据可视化',
      requireAuth: true,
    }
  },
  // {
  //   path: '/imgView',
  //   name:'imgView',
  //   component: () => import('@/views/imgView/index'),
  //   meta:{
  //     title:'照片预览',
  //   }
  // },
  // {
  //   path: '/test',
  //   name:'Test',
  //   component: () => import('@/views/test'),
  //   meta:{
  //     title:'测试页面',
  //   }
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  NProgress.start()
  if (to.meta.requireAuth) {
    if (getToken()) {

      next()

      NProgress.done()
    } else {
      if (from.fullPath != "/") {
      }
      next({
        name: "Login",
        query: {
          redirect: to.fullPath
        },
        replace: true
      })
      NProgress.done()
    }
  } else {
    next()
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
