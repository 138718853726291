/*
 * @Descripttion: 
 * @version: 
 * @Author: liwh
 * @Date: 2022-04-15 11:29:01
 * @LastEditors: liwh
 * @LastEditTime: 2022-12-12 11:20:18
 */

import Vue from 'vue'
import { createApp } from 'vue'
import ElementUI from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import dataV from '@kjgl77/datav-vue3'
// import 'element-plus/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import 'echarts-extension-amap'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/iconfont/font/iconfont.css'
import '@/assets/styles/common/common.scss'
import vueWaves from './directive/waves'
// import 'default-passive-events'
// ElementUI.Dialog.props.lockScroll.default = false;
const app = createApp(App)
app.config.globalProperties.$echarts = echarts;//全局使用
app.use(ElementUI);
app.use(dataV);
app.use(vueWaves)
app.use(router)
app.use(store)
// Vue.config.productionTip = false
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


// 屏蔽错误信息
app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;

app.mount('#app');

if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
  console.log = function () { }
  console.error = function () { }
  console.dir = function () { }
}
// if (process.env.NODE_ENV == 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
//   const src = 'https://unpkg.zhimg.com/eruda@2.4.1';
//   document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
//   document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
// }
  //     const src = 'https://unpkg.zhimg.com/eruda@2.4.1';
  //     document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
  //     document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
